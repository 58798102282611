export const AGILE_ORG = 'theagileexecutive';
export const AGILE_ORG_NAME = 'The Agile Executive'; // Similarly we can create for other Organozations also
export const AGILE_ALLOWED_CATEGORY_COMMUNITY = ['Agile Consultant', 'Consultant'];
export const AGILE_MENTOR_ROLE_TAG = 'Agile Consultant';
export const LABEL_MAPPINGS = [
  { original: 'Client', formatted: 'I’m looking for a consultant' },
  { original: 'Consultant', formatted: 'I am a consultant' }
  // Add more label mappings as needed
];
export const STAR_VENTURE = 'starventure';
export const STAR_VENTURE_NAME = 'star venture';
export const RDP_CREATIVE = 'rdpcreative';
export const ORFIUM = 'orfium';
// export const ORFIUM_GROUP_ID = '66f5320a841f4ff87de6a651';
export const ORFIUM_GROUP_ID = '66fa90dff25799ad4a4bc06e';
export const ORG_TYPES = {
  govt: 'Government & Development Organisation',
  corporate: 'Corporate',
  highEd: 'Higher Education'
};
